@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
  }

/* .fade-enter div {
overflow-y: hidden;
max-height: 0;
}

.fade-enter-active div {
max-height: 50px;
transition: all 500ms ease-in;
}
.fade-exit div {
max-height: 50px;
}
.fade-exit-active div {
max-height: 0;
overflow-y: hidden;
transition: all 500ms ease-in;
} */